import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ICustomRoute } from ".";
import { useAppSelector } from "../../redux/hooks";
import { RootState } from "../../redux/store";
import { USER_ROLES } from "../enum";

interface IRoleRoute extends ICustomRoute {
  authRole: USER_ROLES[];
}

/**
 * @desc: If user is loggedIn and role is `role` then only be these component are visible
 */
function RoleRouteComponent({ authRole, children }: IRoleRoute) {
  const navigate = useNavigate();
  const location = useLocation();
  const { role, isLoggedIn } = useAppSelector((state: RootState) => state.auth);
  useEffect(() => {
    if (!isLoggedIn) {
      navigate("/");
    } else {
      if (!authRole.includes(role as USER_ROLES)) {
        navigate(`/`, {
          state: {
            from: location.pathname,
          },
        });
      }
    }
  }, [authRole, role, navigate, isLoggedIn, location]);
  return <>{children}</>;
}

export default RoleRouteComponent;
