import React from "react";
import { IButton } from ".";

function OutlineButtonComponent({ children, className, ...rest }: IButton) {
  return (
    <button
      className={`bg-transparent  text-[#B90A0A] font-semibold  py-2 px-4 border border-[#B90A0A]  rounded font-avenirLTStdBlack ${className} `}
      {...rest}
    >
      {children}
    </button>
  );
}

export default OutlineButtonComponent;
