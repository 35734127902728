import React from "react";
import Navbar from "./navbar";

function Header() {
  return (
    <div className="w-full flex justify-between mb-[45px]">
      <Navbar />
    </div>
  );
}

export default Header;
