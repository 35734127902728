import React from "react";
import { useAppSelector } from "../../redux/hooks";
import { RootState } from "../../redux/store";

interface ILayout {
  children: React.ReactNode;
}

function Layout({ children }: ILayout) {
  const { currentUser } = useAppSelector((state: RootState) => state.auth);

  return (
    <>
      <div className="bg-white w-full rounded-lg flex justify-between p-[20px] mb-[45px]">
        <div className="leading-7">
          <table>
            <tbody>
              <tr>
                <td className="text-[#A00404]">Employee Name</td>
                <td className="text-[#A00404]">:</td>
                <td>{currentUser.name}</td>
              </tr>
              <tr>
                <td className="text-[#A00404]">Employee Id </td>
                <td className="text-[#A00404]">:</td>
                <td>{currentUser.employeeId}</td>
              </tr>
              <tr>
                <td className="text-[#A00404]">Designation </td>
                <td className="text-[#A00404]">:</td>
                <td>{currentUser.designation}</td>
              </tr>
              <tr>
                <td className="text-[#A00404]">Location </td>
                <td className="text-[#A00404]">:</td>
                <td>{currentUser.location}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className=" bg-[#CF0808] w-[323px] text-white rounded-lg text-center body-font font-avenirLTStdBlack py-[7px]">
          <p>Performance Review Period</p>
          <p>July 2022</p>
          <p>to</p>
          <p>December 2022</p>
        </div>
      </div>
      <div className="bg-white w-full rounded-lg justify-between p-[20px] text-center mb-[45px]">
        <h2 className="body-font font-avenirLTStdBlack text-[31px]">
          Employee Instructions:
        </h2>
        <p>
          Please complete and return this self-evaluation by 25 Dec 2022 Your
          thorough and timely participation in the appraisal process will help
          facilitate a fair and comprehensive review of your progress and
          accomplishments since the last performance review. If you have been
          employed by the company less than a year, substitute references to
          “since the last performance appraisal/review” with ”since you were
          hired” and answer the questions accordingly.
        </p>
      </div>
      <div className="bg-white w-full rounded-lg justify-between  pb-[45px]">
        <h1 className="text-center body-font font-avenirLTStdBlack text-[46px] text-[#B90A0A] mb-[32px]">
          Employee Self-Assessment form
        </h1>
        {children}
      </div>
    </>
  );
}

export default Layout;
