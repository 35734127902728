import urlcat from "urlcat";
import api from "./api";
import { getUserDetailsResponse, transformUserDetails } from "./transform/auth";
import {
  getUserDetailsInput,
  CreateUserInput,
  LoginUserInputType,
  UpdateUserInput,
} from "./types";

export const createUser = async (data: CreateUserInput) => {
  const result = await api.request<getUserDetailsResponse>({
    url: "/v1/user/",
    method: "POST",
    data,
  });
  if (result.remote === "success") {
    return {
      remote: "success",
      data: transformUserDetails(result.data),
    };
  }
  return result;
};

export const updateUserDetails = async (data: UpdateUserInput) => {
  const result = await api.request<getUserDetailsResponse>({
    url: "/v1/user/",
    method: "PUT",
    data,
  });
  if (result.remote === "success") {
    return {
      remote: "success",
      data: transformUserDetails(result.data),
    };
  }
  return result;
};

export const signInUser = async (data: LoginUserInputType) => {
  const result = await api.request({
    url: "/v1/user/login",
    method: "POST",
    data,
  });
  return result;
};

export const getUserDetails = async (data: getUserDetailsInput) => {
  const result = await api.request<getUserDetailsResponse>({
    url: urlcat("/v1/user", { ...data }),
    method: "GET",
  });
  if (result.remote === "success") {
    return {
      remote: "success",
      data: transformUserDetails(result.data),
    };
  }
  return result;
};

export const getUserList = async () => {
  const res = await api.request<getUserDetailsResponse[]>({
    url: `/v1/user/list-hrms`,
    method: "GET",
  });
  if (res.remote === "success") {
    return {
      remote: "success",
      data: res.data.map((data) => transformUserDetails(data)),
    };
  }
  return res;
};

export const sendPassword = async () => {
  const res = await api.request({
    url: `/v1/user/generate-new-password`,
    method: "GET",
  });
  return res;
};
/*-----------------------sendAppraisalApi--------------*/
type sendAppraisalApiParameter = {
  id?:number;
  token?:string;
  name: string;
  amount: string;
  email: string;
  refNumber: number;
  isPromoted: boolean;
  designation: string;
  designationId?:number;
  cycle: string;
  rating: string;
};
export const sendAppraisalApi = async (data: sendAppraisalApiParameter) => {
  const res = await api.request<string>({
    url: `/v1/user/send-appraisal`,
    method: "POST",
    data,
  });
  if (res.remote === "success") {
    return {
      remote: "success",
      message: res.data,
    };
  }
  return res;
};
/*-----------------------End sendAppraisalApi--------------*/

/*-------------------getHrmsUserDetailApi-------------------*/
export const getHrmsUserDetailApi = async (id: number) => {
  const res = await api.request<getUserDetailsResponse>({
    url: `/v1/user/user-details-hrms/${id}`,
    method: "GET",
  });
  if (res.remote === "success") {
    return {
      remote: "success",
      data: transformUserDetails(res.data),
    };
  }
  return res;
};
/*-------------------End getHrmsUserDetailApi-------------------*/

/*-------------------HRMS Login-------------------*/
type loginData = {
  email: string;
  password: string;
};
export const getLoginToken = async (data: loginData) => {
  const res = await api.request<string>({
    url: `/v1/user/get-hrms-token`,
    method: "POST",
    data,
  });
  if (res.remote === "success") {
    return {
      remote: "success",
      token: res.data,
    };
  }
  return res;
};
/*-------------------End HRMS Login-------------------*/
