import SideImage from "./sideImage.png";
import LogoFull from "./logo_full.png";
import Logo from "./logo.png";
import Signature from "./signature.png";
const IMAGES = {
  SideImage,
  LogoFull,
  Logo,
  Signature
};

export default IMAGES;
