import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ICustomRoute } from ".";
import { useAppSelector } from "../../redux/hooks";
import { RootState } from "../../redux/store";
import { USER_ROLES } from "../enum";

/**
 * @desc: If user is loggedOut then only be these component are visible
 */
function UnauthorizedRouteComponent({ children }: ICustomRoute) {
  const navigate = useNavigate();
  const location = useLocation();
  const { isLoggedIn, role } = useAppSelector((state: RootState) => state.auth);
  useEffect(() => {
    if (isLoggedIn) {
      switch (role) {
        case USER_ROLES.admin:
          navigate(location.state?.from || "/employee-list");
          break;
        case USER_ROLES.employee:
          navigate(location.state?.from || "/questions/1");
          break;
        default:
          break;
      }
    }
  }, [isLoggedIn, location.state?.from, navigate, role]);

  return <>{children}</>;
}

export default UnauthorizedRouteComponent;
