export enum USER_ROLES {
  admin = "admin",
  employee = "employee",
}

export enum QUESTION_TYPE {
  input = "input",
  rating = "rating",
}

export enum SUBMISSION_STATUS {
  completed = "completed",
  pending = "pending",
  submitted = "submitted",
}
