import React from "react";
import { useParams } from "react-router-dom";
import Layout from "./layout";
import StepOne from "./stepOne";
import StepTwo from "./stepTwo";

function Questions() {
  const location = useParams();

  return (
    <div>
      <Layout>{location.step === "1" ? <StepOne /> : <StepTwo />}</Layout>
    </div>
  );
}

export default Questions;
