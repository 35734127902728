import React, { useState } from "react";
import { IInput } from ".";
import SVG from "../../assets/svg";
import { ErrorMessage } from "../error";

function LabeledInputComponent({
  label,
  className,
  password,
  type,
  errorMessage,
  icon,
  ...rest
}: IInput) {
  const [isVisible, setIsVisible] = useState<boolean>(false);
  return (
    <div className="flex flex-col mb-[15px] w-full">
      <label className="body-font font-avenirLTStdBlack">{label}</label>
      <div className="flex justify-end items-center relative w-full">
        <input
          className={`form-control relative block w-full h-[50px] px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none ${className}`}
          type={isVisible ? "text" : type}
          {...rest}
        />
        {icon ? (
          <div
            onClick={() => setIsVisible(!isVisible)}
            className="absolute mr-0.5 w-10 pointer-events-auto cursor-pointer"
          >
            {icon}
          </div>
        ) : (
          ""
        )}
        {password && (
          <div
            onClick={() => setIsVisible(!isVisible)}
            className="absolute mr-2 w-10 pointer-events-auto cursor-pointer"
          >
            {!isVisible ? <SVG.EyeOpen /> : <SVG.EyeOff />}
          </div>
        )}
      </div>
      <ErrorMessage message={errorMessage || ""} />
    </div>
  );
}

export default LabeledInputComponent;
