import React, { useState } from "react";
import { FilledButton } from "../../component/button";
import { ErrorMessage } from "../../component/error";
import { SelectInput, TextareaInput } from "../../component/input";
import { LineLoader } from "../../component/loader";
import { QUESTION_TYPE } from "../../utils/enum";
import { createQuestionInput } from "../../api/types";
import { createAdminQuestion } from "../../api/question";
import { useAppDispatch } from "../../redux/hooks";
import { addQuestionToQuestionList } from "../../redux/slice/question";

interface IEditQuestion {
  questionId?: string;
  handleClose: () => void;
}
function EditQuestion({ questionId, handleClose }: IEditQuestion) {
  const dispatch = useAppDispatch();
  const [form, setForm] = useState<createQuestionInput>({
    isVisibleToEmployer: true,
    title: "",
    type: QUESTION_TYPE.input,
  });
  const [errors, setErrors] = useState<any>({});
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const handleChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => {
    setForm((prevState) => ({ ...prevState, [e.target.name]: e.target.value }));
  };

  const validateForm = () => {
    const errors: any = {};
    if (!form.title) {
      errors.title = "Title is required";
    }

    return { isValid: !Object.keys(errors).length, errors };
  };
  // const handleUpdateUser = async (userId: string) => {
  //   const newForm: UpdateUserInput = { ...form, id: userId };
  //   if (!newForm.password) {
  //     delete newForm.password;
  //   }
  //   const res = await updateUserDetails({ ...newForm });
  //   if (res.remote === "success") {
  //     dispatch(updateUserOfEmployeeList({ userId, data: res.data }));
  //   }
  // };

  const handleAdminQuestion = async () => {
    const res = await createAdminQuestion(form);
    if (res.remote === "success") {
      dispatch(addQuestionToQuestionList(res.data));
      handleClose();
    }
  };

  const handleSubmit = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setIsLoading(true);
    e.preventDefault();
    const { errors, isValid } = validateForm();
    if (!isValid) {
      setErrors(errors);
    } else {
      if (questionId) {
        console.log("Updating...");
        // handleUpdateUser(questionId);
      } else {
        handleAdminQuestion();
      }
    }
    setIsLoading(false);
  };

  // useEffect(() => {
  //   if (questionId) getUserDetailsById(questionId);
  // }, [getUserDetailsById, questionId]);
  return (
    <div>
      <form className="w-full">
        <div className="mb-[20px]">
          <TextareaInput
            label="Title"
            placeholder="Title"
            name="title"
            value={form.title}
            onChange={handleChange}
            errorMessage={errors.title}
          />
        </div>
        <div>
          <SelectInput
            options={[
              {
                value: QUESTION_TYPE.input,
                title: "Input",
              },
              {
                value: QUESTION_TYPE.rating,
                title: "Rating",
              },
            ]}
            name="type"
            label="Question Type"
            placeholder="Type"
            value={form.type}
            onChange={handleChange}
          />
        </div>
        <ErrorMessage message={errors.message || ""} />

        <div className="mt-[54px] w-full">
          <FilledButton
            className="w-full"
            onClick={handleSubmit}
            disabled={isLoading}
          >
            {isLoading ? (
              <LineLoader />
            ) : questionId ? (
              "Update Question"
            ) : (
              "Create Question"
            )}
          </FilledButton>
        </div>
      </form>
    </div>
  );
}

export default EditQuestion;
