import React, { useCallback, useEffect, useState } from "react";
import SVG from "../../assets/svg";
import { LabeledInput } from "../../component/input";
import { toast, ToastContainer } from "react-toastify";
import { FilledButton } from "../../component/button";
import Popup from "../../component/popup";
import AddUser from "./component/addUser";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { RootState } from "../../redux/store";
import { getUserList, sendPassword } from "../../api/auth";
import { setEmployeeList } from "../../redux/slice/employee";
import AdminLayout from "../../component/adminLayout";
import { Link } from "react-router-dom";
import { SUBMISSION_STATUS } from "../../utils/enum";
import HrmsLogin from "./component/loginHrms";

function EmployeeList() {
  const dispatch = useAppDispatch();
  const { employeeList } = useAppSelector((state: RootState) => state.employee);
  const [showAddUser, setShowAddUser] = useState(false);
  const [sendingEmail, setSendingEmail] = useState(false);
  const [isLoginClick, setIsLoginClick] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState("");
  const handleGetUserList = useCallback(async () => {
    const res = await getUserList();
    if (res.remote === "success") {
      dispatch(setEmployeeList(res.data));
    }
  }, [dispatch]);
  const handleEdit = (id: string) => {
    setSelectedUserId(id);
  };
  const handleCloseEditUser = () => {
    setShowAddUser(false);
    setSelectedUserId("");
  };
  useEffect(() => {
    setShowAddUser(!!selectedUserId);
  }, [selectedUserId]);

  const sendPasswordToEmployee = async () => {
    setSendingEmail(true);
    const res = await sendPassword();
    if (res.remote === "success") {
      toast("Done");
    } else {
      toast("Something went wrong");
    }
    setSendingEmail(false);
  };

  useEffect(() => {
    handleGetUserList();
  }, [handleGetUserList]);
  return (
    
      <AdminLayout
        title="Employee List"
        sideContent={
          <>
            {/* <FilledButton
              className="w-[100%] mr-3 h-[75%]"
              onClick={sendPasswordToEmployee}
              disabled={sendingEmail}
            >
              Send Passwords to Employees
            </FilledButton> */}
            {/* <FilledButton
              className="w-[50%] mr-3 h-[75%]"
              onClick={() => setShowAddUser(true)}
            >
              Add User
            </FilledButton> */}
            <FilledButton
              className="w-[50%] mr-3 h-[75%]"
              onClick={() => setIsLoginClick(!isLoginClick)}
            >
              HRMS Login
            </FilledButton>
            <LabeledInput
              type="text"
              label=""
              placeholder="Search by employee name, code"
              icon={<SVG.Search />}
            />
          </>
        }
      >
        <div className="overflow-x-auto relative">
          <table className="w-full text-sm text-left text-gray-500 ">
            <thead className="text-xs text-gray-700 uppercase bg-gray-50 ">
              <tr>
                <th scope="col" className="py-3 px-6">
                  Employee Name
                </th>
                <th scope="col" className="py-3 px-6">
                  Employee ID
                </th>
                <th scope="col" className="py-3 px-6">
                  Role
                </th>
                <th scope="col" className="py-3 px-6">
                  Created At
                </th>
                <th scope="col" className="py-3 px-6">
                  Submission Status
                </th>
                <th scope="col" className="py-3 px-6">
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {employeeList.map((employee, index) => {
                return (
                  <tr className="bg-white border-b ">
                    <td className="py-4 px-6 font-medium text-gray-900 whitespace-nowrap ">
                      <Link to={`/admin-preview/${employee.id}/${index+1}`}>
                        {employee.name}
                      </Link>
                    </td>
                    <td className="py-4 px-6">{employee.employeeId}</td>
                    <td className="py-4 px-6">{employee.role}</td>
                    <td className="py-4 px-6">{employee.createdAt}</td>
                    <td className="py-4 px-6">
                      <div
                        className={`${
                          SUBMISSION_STATUS.pending === employee.status
                            ? "text-[#9E0000]"
                            : SUBMISSION_STATUS.submitted === employee.status
                            ? "text-[#e62d05]"
                            : SUBMISSION_STATUS.completed === employee.status
                            ? "text-[#009E3F]"
                            : ""
                        }`}
                      >
                        {employee.status}
                      </div>
                    </td>
                    <td className="py-4 px-6">
                      <FilledButton onClick={() => handleEdit(employee.id)} disabled={true}>
                        Edit
                      </FilledButton>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <ToastContainer />
        {showAddUser && (
          <Popup title="Add Employee" handleClose={handleCloseEditUser}>
            <AddUser
              userId={selectedUserId}
              handleClose={handleCloseEditUser}
            />
          </Popup>
        )}

        {isLoginClick && (
          <Popup title="Hrms Login" handleClose={()=>setIsLoginClick(!isLoginClick)}>
            <HrmsLogin setIsClick={setIsLoginClick}/>
          </Popup>
        )}
      </AdminLayout>
    
  );
}

export default EmployeeList;
