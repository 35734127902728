import api from "./api";
import {
  QuestionResponse,
  transformQuestionResponse,
} from "./transform/question";
import { createQuestionInput } from "./types";

export const getAdminQuestion = async () => {
  const res = await api.request<QuestionResponse[]>({
    url: "v1/question",
    method: "GET",
  });
  if (res.remote === "success") {
    return {
      remote: "success",
      data: res.data.map((d) => transformQuestionResponse(d)),
    };
  }
  return res;
};
export const createAdminQuestion = async (data: createQuestionInput) => {
  const res = await api.request<QuestionResponse>({
    url: "v1/question",
    method: "POST",
    data,
  });
  if (res.remote === "success") {
    return {
      remote: "success",
      data: transformQuestionResponse(res.data),
    };
  }
  return res;
};
