import { QUESTION_TYPE } from "../../utils/enum";

export type QuestionResponse = {
  _id: string;
  createdAt: string;
  isActive: boolean;
  title: string;
  type: QUESTION_TYPE;
};

export type AdminQuestion = {
  id: string;
  createdAt: string;
  isActive: boolean;
  title: string;
  type: QUESTION_TYPE;
};

export const transformQuestionResponse = (
  data: QuestionResponse
): AdminQuestion => {
  return {
    id: data._id,
    createdAt: data.createdAt,
    isActive: data.isActive,
    title: data.title,
    type: data.type,
  };
};
