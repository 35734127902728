import React, { useCallback, useEffect, useState } from "react";
import { FilledButton } from "../../../component/button";
import { ErrorMessage } from "../../../component/error";
import { LabeledInput, SelectInput } from "../../../component/input";
import { LineLoader } from "../../../component/loader";
import {
  createUser,
  getUserDetails,
  updateUserDetails,
} from "../../../api/auth";
import { USER_ROLES } from "../../../utils/enum";
import { CreateUserInput, UpdateUserInput } from "../../../api/types";
import { useAppDispatch } from "../../../redux/hooks";
import {
  addUserToEmployeeList,
  updateUserOfEmployeeList,
} from "../../../redux/slice/employee";

interface IEditUser {
  userId?: string;
  handleClose: () => void;
}
function EditUser({ userId, handleClose }: IEditUser) {
  const dispatch = useAppDispatch();
  const [form, setForm] = useState<CreateUserInput>({
    email: "",
    password: "digimonk@123",
    employeeId: "",
    name: "",
    location: "gwalior",
    designation: "",
    role: USER_ROLES.employee,
  });
  const [errors, setErrors] = useState<any>({});
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    setForm((prevState) => ({ ...prevState, [e.target.name]: e.target.value }));
  };
  const getUserDetailsById = useCallback(async (userId: string) => {
    const res = await getUserDetails({ userId });
    if (res.remote === "success") {
      setForm({
        email: res.data.email,
        employeeId: res.data.employeeId,
        name: res.data.name,
        password: "",
        role: res.data.role as USER_ROLES,
        location: res.data.location,
        designation: res.data.designation,
      });
    }
  }, []);

  const validateForm = () => {
    const errors: any = {};
    if (!form.email) {
      errors.email = "Email is required";
    }
    // optional when editing user
    if (!userId) {
      if (!form.password) {
        errors.password = "Password is required";
      }
    }
    if (!form.employeeId) {
      errors.employeeId = "Employee Id is required";
    }
    if (!form.name) {
      errors.name = "Name is required";
    }
    if (!form.designation) {
      errors.designation = "Designation is required";
    }
    return { isValid: !Object.keys(errors).length, errors };
  };
  const handleUpdateUser = async (userId: string) => {
    const newForm: UpdateUserInput = { ...form, id: userId };
    if (!newForm.password) {
      delete newForm.password;
    }
    const res = await updateUserDetails({ ...newForm });
    if (res.remote === "success") {
      dispatch(updateUserOfEmployeeList({ userId, data: res.data }));
    }
  };

  const handleCreateUser = async () => {
    const res = await createUser(form);
    if (res.remote === "success") {
      dispatch(addUserToEmployeeList(res.data));
      handleClose();
    }
  };

  const handleSubmit = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setIsLoading(true);
    e.preventDefault();
    const { errors, isValid } = validateForm();
    if (!isValid) {
      setErrors(errors);
    } else {
      if (userId) {
        console.log("update");
        handleUpdateUser(userId);
      } else {
        handleCreateUser();
      }
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (userId) getUserDetailsById(userId);
  }, [getUserDetailsById, userId]);
  return (
    <div>
      <form className="w-full">
        <div className="mb-[20px]">
          <LabeledInput
            label="Name"
            placeholder="Name"
            name="name"
            value={form.name}
            onChange={handleChange}
            errorMessage={errors.name}
          />
        </div>
        <div>
          <LabeledInput
            label="Email"
            placeholder="Email"
            type="email"
            name="email"
            value={form.email}
            onChange={handleChange}
            errorMessage={errors.email}
          />
        </div>
        <div>
          <LabeledInput
            label="Employee Id"
            placeholder="Employee Id"
            name="employeeId"
            value={form.employeeId}
            onChange={handleChange}
            errorMessage={errors.employeeId}
          />
        </div>
        <div>
          <SelectInput
            options={[
              {
                value: USER_ROLES.admin,
                title: "Admin",
              },
              {
                value: USER_ROLES.employee,
                title: "Employee",
              },
            ]}
            name="role"
            label="Employee Role"
            placeholder="Role"
            value={form.role}
            onChange={handleChange}
          />
        </div>
        <div>
          <SelectInput
            options={[
              {
                value: "gwalior",
                title: "Gwalior",
              },
              {
                value: "bhopal",
                title: "Bhopal",
              },
            ]}
            name="location"
            label="Location"
            placeholder="Location"
            value={form.location}
            onChange={handleChange}
          />
        </div>
        <div>
          <LabeledInput
            label="Designation"
            placeholder="Designation"
            name="designation"
            password
            value={form.designation}
            onChange={handleChange}
            errorMessage={errors.designation}
          />
        </div>
        <div>
          <LabeledInput
            label="Password"
            placeholder="Password"
            type="password"
            name="password"
            password
            value={form.password}
            onChange={handleChange}
            errorMessage={errors.password}
          />
        </div>
        <ErrorMessage message={errors.message || ""} />

        <div className="mt-[54px] w-full">
          <FilledButton
            className="w-full"
            onClick={handleSubmit}
            disabled={isLoading}
          >
            {isLoading ? <LineLoader /> : "Create Employee"}
          </FilledButton>
        </div>
      </form>
    </div>
  );
}

export default EditUser;
