import { useCallback, useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import {
  getEmployerQuestions,
  reviewSubmissionFrom,
} from "../../api/employerQuestions";
import { AnsweredQuestionType } from "../../api/transform/employerQuestions";
import { reviewSubmissionFromInput } from "../../api/types";
import Appraisal from "../../component/appraisal";
import { FilledButton } from "../../component/button";
import { LabeledInput, SelectInput } from "../../component/input";
import TextareaComponent from "../../component/input/textarea";
import Popup from "../../component/popup";
import { USER } from "../../redux/slice/auth";
import axios from "axios";
import { designation, designationListApi } from "../../api/choice";
import { getHrmsUserDetailApi, sendAppraisalApi } from "../../api/auth";
import { useSelector } from "react-redux";

function AdminPreview() {
  const params = useParams();
  const { search } = useLocation();
  const [questions, setQuestions] = useState<AnsweredQuestionType[]>([]);
  const [submissionId, setSubmissionId] = useState("");
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isDraft, setIsDraft] = useState<boolean>(false);
  const [isPromoted, setIsPromoted] = useState<boolean>(false);
  const [designation, setDesignation] = useState<string>("");
  const [designationId, setDesignationId] = useState<number>();
  const [rating, setRating] = useState<string>("Meet Expectation");
  const [cycle, setCycle] = useState<string>("Jul 2023 - Dec 2023");
  const [amount, setAmount] = useState("0");
  const [designationList, setDesignationList] = useState<designation[]>([]);
  const [isGeneratingLetter, setIsGeneratingLetter] = useState(false);
  const [user, setUser] = useState<USER>({
    id: "",
    name: "",
    email: "",
    employeeId: "",
    designation: "",
    designationId:undefined,
    location: "",
    createdAt: "",
  });
  const token: string = useSelector((state: any) => state.auth.token);
  const getUserDetails = async () => {
    const id: number = Number(params.id);
    const res: any = await getHrmsUserDetailApi(id);
    setUser({
      id: `${res.data.id}`,
      name: `${res.data.name}`,
      email: `${res.data.email}`,
      employeeId: `${res.data.employeeId}`,
      designation: `${res.data.designation}`,
      designationId:res.data.designationId,
      location: `${res.data.location}`,
      createdAt: `${res.data.createdAt}`,
    });

    setDesignationId(res.data.designationId);
  };
  const setDesignationHandler = async () => {
    const res = await designationListApi();
    if (res?.remote === "success") {
      setDesignationList(res.data);
    }
  };
  useEffect(() => {
    setDesignationHandler();
    getUserDetails();
  }, []);
  const getQuestions = useCallback(async () => {
    const res = await getEmployerQuestions({ userId: params.id });
    if (res.remote === "success") {
      setQuestions(res.data.questions);
      setSubmissionId(res.data.id);
      setIsDraft(res.data.isDraft);
      setIsPromoted(res.data.isPromoted || false);
      setDesignation(res.data.promotedTo || res.data.userId.designation || "");
      setAmount(res.data.incrementAmount);
      setUser(res.data.userId);
    }
  }, [params.id]);

  const toggleGenerateAppraisalLetter = () => {
    setIsGeneratingLetter(!isGeneratingLetter);
  };

  const setDesignationById = (id:number):void=>{
    const item = designationList.find((item:designation)=>item.id===id);
    console.log(item?.name)
    setDesignation(`${item?.name}`)
  }

  const handleGenerateAppraisalLetter = async () => {
    try {
      let gridElement = document.getElementById("divToPrint");
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/v1/user/html-2-pdf`,
        {
          name: `${user.name}`,
          amount: `${amount}`,
          isPromoted: isPromoted,
          refNumber: Number(params.index),
          designation: designation,
          cycle: `${cycle}`,
          rating: rating,
        }
      );
      if (gridElement) {
        const downloadLink = document.createElement("a");
        const fileName = `${user.name}.pdf`;
        downloadLink.href = response.data;
        downloadLink.download = fileName;
        downloadLink.click();
      }
    } catch (error) {
      console.log({ error }, error);
    }
  };
  const handleSendAppraisalLetter = async () => {
    if (token === "") {
      toast("please login first on hrms");
    } else {
      const res = await sendAppraisalApi({
        id: Number(params.id),
        token:token,
        name: `${user.name}`,
        amount: `${amount}`,
        email: `${user.email}`,
        refNumber: Number(params.index),
        isPromoted: isPromoted,
        designation: designation,
        designationId,
        cycle: cycle,
        rating: rating,
      });
      if(res.remote==="success"){
        toast("send successfully");
      }else{
        toast("Request fail");
      }
    }
  };
  const handleSubmitReview = async () => {
    const payload: reviewSubmissionFromInput = {
      submissionId,
      isPromoted,
      promotedTo: designation,
      incrementAmount: amount,
    };
    const res = await reviewSubmissionFrom(payload);
    if (res.remote === "success") {
      toast("Reviewed");
    } else {
      toast("Something went wrong");
    }
  };
  console.log({designationId});
  useEffect(() => {
    getQuestions();
  }, [getQuestions]);

  return (
    <div>
      <ToastContainer />
      {questions.map((question, index) => {
        if (question.type === "input") {
          return (
            <div className="flex flex-col pr-[90px] pl-[90px]" key={index}>
              <span className="body-font font-avenirLTStdBlack text-[24px] mb-[29px]">
                {index + 1}. {question.title}
              </span>
              <TextareaComponent
                placeholder="Write On..."
                className="min-h-[153px] py-1.5"
                value={question.answer}
                disabled
                // onChange={handleChangeAnswer(question.id)}
              />
            </div>
          );
        } else {
          return (
            <div className=" flex flex-col pr-[90px] pl-[90px]">
              <div className="font-avenirLTStdRoman w-[100%]">
                <span className="body-font font-avenirLTStdBlack text-[24px] mb-[29px]">
                  {index + 1}. {question.title}
                </span>
                <div className="relative w-full lg:max-w-sm">
                  <div>Your Rating: </div>
                  <select
                    value={question.rating}
                    className="w-full p-2.5 text-[16px]  text-gray-500 bg-white border rounded-md shadow-sm outline-none appearance-none font-avenirLTStdBook"
                    disabled={true}
                  >
                    <option>Choose Rating</option>
                    <option value="1">Outstanding</option>
                    <option value="2">Meet Expectation</option>
                    <option value="3">
                      Inexperienced or Improvement Needed or Performance
                      improvement
                    </option>
                  </select>
                </div>
                <div className="min-h-[153px] py-1.5">
                  <div>Comment: </div>
                  <TextareaComponent
                    placeholder="Your Comment"
                    value={question.answer}
                    disabled={true}
                  />
                </div>
              </div>
            </div>
          );
        }
      })}
      <div className="mb-20 mt-20">
        <div className=" bg-[#F7F8FA] w-full flex items-center px-[24px] rounded-lg border-2 border-[#EFF0F4] text-[24px] body-font font-avenirLTStdBlack">
          <div className="font-avenirLTStdRoman w-full">
            <div className="flex justify-between">
              <p>Promoted</p>
              <div className="flex justify-between w-[50%]">
                <div
                  className="flex items-center "
                  onClick={(e) => setIsPromoted(true)}
                >
                  <input
                    id="default-radio-1"
                    type="radio"
                    checked={isPromoted}
                    name="default-radio"
                    className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                  />
                  <label
                    htmlFor="default-radio-1"
                    className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                  >
                    Yes
                  </label>
                </div>
                <div className="flex items-center ">
                  <input
                    checked={!isPromoted}
                    id="default-radio-2"
                    type="radio"
                    value="no"
                    name="default-radio"
                    onClick={(e) => setIsPromoted(false)}
                    className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                  />
                  <label
                    htmlFor="default-radio-2"
                    className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                  >
                    No
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className=" bg-[#F7F8FA] w-full flex items-center px-[24px] rounded-lg border-2 border-[#EFF0F4] text-[24px] body-font font-avenirLTStdBlack">
          <div className="font-avenirLTStdRoman w-full">
            <p>Appraisal Cycle</p>
            <SelectInput
              options={[
                {
                  value: "Jul 2023 - Dec 2023",
                  title: "Jul 2023 - Dec 2023",
                },
                {
                  value: "Jan 2023 - Dec 2023",
                  title: "Jan 2023 - Dec 2023",
                },
              ]}
              value={cycle}
              onChange={(e) => setCycle(e.target.value)}
            ></SelectInput>
          </div>
        </div>
        {isPromoted && (
          <div className=" bg-[#F7F8FA] w-full flex items-center px-[24px] rounded-lg border-2 border-[#EFF0F4] text-[24px] body-font font-avenirLTStdBlack">
            <div className="font-avenirLTStdRoman w-full">
              <p>Choose Designation</p>
              <SelectInput
                // options={[{ value: "", title: "" }, ...DESIGNATION_ARRAY]}
                options={[
                  { value: "", title: "" },
                  ...designationList.map((data: designation) => {
                    return { value: data.id, title: data.name };
                  }),
                ]}
                value={designationId}
                onChange={(e) => {
                  setDesignationById(Number(e.target.value))
                  setDesignationId(Number(e.target.value))
                }}
              ></SelectInput>
            </div>
          </div>
        )}
        <div className=" bg-[#F7F8FA] w-full flex items-center px-[24px] rounded-lg border-2 border-[#EFF0F4] text-[24px] body-font font-avenirLTStdBlack">
          <div className="font-avenirLTStdRoman w-full">
            <p>Rating</p>
            <SelectInput
              options={[
                {
                  value: "Outstanding",
                  title: "Outstanding",
                },
                {
                  value: "Meet Expectation",
                  title: "Meet Expectation",
                },
                {
                  value:
                    "Inexperienced or Improvement Needed or Performance improvement",
                  title:
                    "Inexperienced or Improvement Needed or Performance improvement",
                },
              ]}
              value={rating}
              onChange={(e) => setRating(e.target.value)}
            ></SelectInput>
          </div>
        </div>
        <div className=" bg-[#F7F8FA] w-full flex items-center px-[24px] rounded-lg border-2 border-[#EFF0F4] text-[24px] body-font font-avenirLTStdBlack">
          <div className="font-avenirLTStdRoman w-full flex">
            <p>Increment Amount</p>
            <div className="flex ml-20">
              Rs.{" "}
              <LabeledInput
                label=""
                placeholder="00"
                value={amount}
                type="number"
                onChange={(e) => setAmount(e.target.value)}
              />
            </div>
          </div>
        </div>
        {/* <FilledButton onClick={handleSubmitReview}>Submit Review</FilledButton> */}
        <FilledButton onClick={toggleGenerateAppraisalLetter}>
          Generate Appraisal Letter
        </FilledButton>
      </div>
      <ToastContainer />
      {isGeneratingLetter && (
        <Popup
          title="Appraisal Letter"
          handleClose={toggleGenerateAppraisalLetter}
        >
          <Appraisal
            name={`${user.name}`}
            email={`${user.email}`}
            amount={`${amount}`}
            isPromoted={isPromoted}
            designation={designation}
            rating={rating}
            refNumber={Number(params.index)}
            cycle={cycle}
            handleGenerateAppraisalLetter={handleGenerateAppraisalLetter}
            handleSendAppraisalLetter={handleSendAppraisalLetter}
          />
        </Popup>
      )}
    </div>
  );
}

export default AdminPreview;
