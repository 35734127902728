import React from "react";

interface IAdminLayout {
  children?: React.ReactNode | React.ReactNode[];
  title: string;
  sideContent?: React.ReactNode | React.ReactNode[];
}

function AdminLayout({ children, title, sideContent }: IAdminLayout) {
  return (
    <div className="bg-white px-[29px] py-[20px]">
      <div className="flex justify-between items-center">
        <h1 className="w-[20%] body-font font-avenirLTStdBlack text-[#B90A0A] text-[24px]">
          {title}
        </h1>
        <div className="flex justify-end  w-[50%]">{sideContent}</div>
      </div>
      <div>{children}</div>
    </div>
  );
}

export default AdminLayout;
