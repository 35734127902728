import React from "react";

type optionType = {
  value: number|string;
  title: string;
};
interface ISelectPops
  extends React.DetailedHTMLProps<
    React.SelectHTMLAttributes<HTMLSelectElement>,
    HTMLSelectElement
  > {
  label?: string;
  options: optionType[];
}
function SelectInputComponent({
  label,
  options,
  className,
  ...rest
}: ISelectPops) {
  return (
    <div className="flex flex-col mb-[15px] w-full">
      <label className="body-font font-avenirLTStdBlack">{label}</label>
      <div className="flex justify-end items-center relative w-full">
        <select
          className={`w-full p-2.5 text-[16px]  text-gray-500 bg-white border rounded-md shadow-sm outline-none appearance-none font-avenirLTStdBook ${className}`}
          {...rest}
        >
          {options.map((opt) => (
            <option value={opt.value}>{opt.title}</option>
          ))}
        </select>
      </div>
    </div>
  );
}

export default SelectInputComponent;
