import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { USER } from "./auth";

interface IEmployeeInitialState {
  employeeList: USER[];
}

const initialState: IEmployeeInitialState = {
  employeeList: [],
};

export const authSlice = createSlice({
  name: "employeeList",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setEmployeeList: (state, action: PayloadAction<USER[]>) => {
      state.employeeList = action.payload;
    },
    addUserToEmployeeList: (state, action: PayloadAction<USER>) => {
      state.employeeList = [...state.employeeList, action.payload];
    },
    updateUserOfEmployeeList: (
      state,
      action: PayloadAction<{ userId: string; data: USER }>
    ) => {
      state.employeeList = state.employeeList.map((employee) => {
        if (employee.id === action.payload.userId) {
          return action.payload.data;
        }
        return employee;
      });
    },
  },
});
export const {
  setEmployeeList,
  addUserToEmployeeList,
  updateUserOfEmployeeList,
} = authSlice.actions;
export default authSlice.reducer;
