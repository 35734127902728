import { USER } from "../../redux/slice/auth";
import { QUESTION_TYPE } from "../../utils/enum";
import { getUserDetailsResponse, transformUserDetails } from "./auth";

export type answeredQuestionResponseType = {
  _id: string;
  submissionId: string;
  type: QUESTION_TYPE;
  originalQuestion: string;
  answer?: string;
  title: string;
  rating?: string;
};

export type getEmployerQuestionsResponse = {
  _id: string;
  userId: getUserDetailsResponse;
  isDraft: boolean;
  questions: answeredQuestionResponseType[];
  isPromoted: boolean;
  promotedTo: string;
  incrementAmount: string;
};

export type AnsweredQuestionType = {
  id: string;
  submissionId: string;
  type: QUESTION_TYPE;
  originalQuestion: string;
  answer: string;
  title: string;
  rating: string;
  error?: string;
};

export type AnsweredEmployerQuestionsType = {
  id: string;
  userId: USER;
  isDraft: boolean;
  questions: AnsweredQuestionType[];
  isPromoted?: boolean;
  promotedTo?: string;
  incrementAmount: string;
};

export const transformGetEmployerQuestionsResponse = (
  data: getEmployerQuestionsResponse
): AnsweredEmployerQuestionsType => {
  return {
    id: data._id,
    userId: transformUserDetails(data.userId),
    isDraft: data.isDraft,
    questions: data.questions.map((question) => ({
      id: question._id,
      submissionId: question.submissionId,
      type: question.type,
      originalQuestion: question.originalQuestion,
      answer: question.answer || "",
      title: question.title,
      rating: question.rating || "",
    })),
    isPromoted: data.isPromoted,
    promotedTo: data.promotedTo,
    incrementAmount: data.incrementAmount,
  };
};
