import AdminPreview from "../../pages/adminPreview";
import AdminQuestions from "../../pages/adminQuestions";
import EmployeeList from "../../pages/employeeList";
import Login from "../../pages/login";
import Questions from "../../pages/questions";

import { USER_ROLES } from "../enum";

type Route = {
  id: string;
  name: string;
  path: string;
  access: USER_ROLES[];
  element: () => JSX.Element;
};

export const UNAUTHORIZED_ROUTES: Omit<Route, "access">[] = [
  {
    id: "login",
    name: "Login",
    path: "/",
    element: Login,
  },
];

export const AUTHORIZED_ROUTES: Route[] = [
  {
    id: "question",
    name: "Questions",
    path: "/questions/:step",
    access: [USER_ROLES.admin, USER_ROLES.employee],
    element: Questions,
  },
  {
    id: "employeeList",
    name: "Employee List",
    path: "/employee-list",
    access: [USER_ROLES.admin],
    element: EmployeeList,
  },
  {
    id: "adminQuestions",
    name: "Admin Questions",
    path: "/admin-questions",
    access: [USER_ROLES.admin],
    element: AdminQuestions,
  },
  {
    id: "previewAdmin",
    name: "Preview Questions",
    path: "/admin-preview/:id/:index",
    access: [USER_ROLES.admin],
    element: AdminPreview,
  },
];
