import { useState } from "react";
import { signInUser } from "../../api/auth";
import { LoginUserInputType } from "../../api/types";
import { FilledButton } from "../../component/button";
import { ErrorMessage } from "../../component/error";
import { LabeledInput } from "../../component/input";
import { LineLoader } from "../../component/loader";
import { useAppDispatch } from "../../redux/hooks";
import { setIsLoggedIn } from "../../redux/slice/auth";

function Form() {
  const dispatch = useAppDispatch();
  const [form, setForm] = useState<LoginUserInputType>({
    email: "",
    password: "",
  });
  const [errors, setErrors] = useState<any>({});
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setForm((prevState) => ({ ...prevState, [e.target.name]: e.target.value }));
  };
  const validateForm = () => {
    const errors: any = {};
    if (!form.email) {
      errors.email = "Email is required";
    }
    if (!form.password) {
      errors.password = "Password is required";
    }
    return { isValid: !Object.keys(errors).length, errors };
  };
  const handleSubmit = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    setIsLoading(true);
    e.preventDefault();
    setErrors({});
    const { isValid, errors } = validateForm();
    if (!isValid) {
      setErrors(errors);
    } else {
      const res = await signInUser(form);
      if (res.remote === "success") {
        dispatch(setIsLoggedIn(true));
      } else {
        console.log(res);
        setErrors({ message: "Invalid Email or Password" });
      }
    }
    setIsLoading(false);
  };
  return (
    <div>
      <div className="mb-[45px]">
        <div className="body-font font-avenirLTStdBlack text-[28px] mb-[14px]">
          Sign In
        </div>
        <p className="text-[#3A3737]">
          Enter your email and password for <br />
          signing in.
        </p>
      </div>
      <form className="w-full">
        <div className="mb-[20px]">
          <LabeledInput
            label="Email Address"
            placeholder="Your Company Email"
            type="email"
            name="email"
            value={form.email}
            onChange={handleChange}
            errorMessage={errors.email}
          />
        </div>
        <div>
          <LabeledInput
            label="Password"
            placeholder="Your Password"
            type="password"
            password
            value={form.password}
            name="password"
            onChange={handleChange}
            errorMessage={errors.password}
          />
        </div>
        <ErrorMessage message={errors.message || ""} />

        <div className="mt-[54px] w-full">
          <FilledButton
            className="w-full"
            onClick={(e) => handleSubmit(e)}
            disabled={isLoading}
          >
            {isLoading ? <LineLoader /> : "Sign In"}
          </FilledButton>
        </div>
      </form>
    </div>
  );
}

export default Form;
