import React from "react";
import { ErrorMessage } from "../error";

interface ITextareaComponent
  extends React.DetailedHTMLProps<
    React.TextareaHTMLAttributes<HTMLTextAreaElement>,
    HTMLTextAreaElement
  > {
  label?: string;
  errorMessage?: string;
}

function TextareaComponent({
  label,
  errorMessage,
  className,
  ...rest
}: ITextareaComponent) {
  return (
    <div className="flex flex-col mb-[15px] w-full">
      <label className="body-font font-avenirLTStdBlack">{label}</label>
      <div className="flex justify-end items-center relative w-full">
        <textarea
          className={`form-control block w-full px-3 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-[#B90A0A] focus:outline-none ${className}`}
          {...rest}
        />
      </div>
      <ErrorMessage message={errorMessage || ""} />
    </div>
  );
}

export default TextareaComponent;
