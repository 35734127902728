import React, { useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import {
  getEmployerQuestions,
  submitSubmissionFrom,
} from "../../../api/employerQuestions";
import { AnsweredQuestionType } from "../../../api/transform/employerQuestions";
import { submitSubmissionFromInput } from "../../../api/types";
import { FilledButton } from "../../../component/button";
import TextareaComponent from "../../../component/input/textarea";
import { QUESTION_TYPE } from "../../../utils/enum";

function StepTwo() {
  const [questions, setQuestions] = useState<AnsweredQuestionType[]>([]);
  const [submissionId, setSubmissionId] = useState<string>("");
  const [isDraft, setIsDraft] = useState<boolean>(false);
  const getQuestions = async () => {
    const res = await getEmployerQuestions({ type: QUESTION_TYPE.rating });
    if (res.remote === "success") {
      setSubmissionId(res.data.id);
      setQuestions(res.data.questions);
      setIsDraft(res.data.isDraft);
    }
  };
  useEffect(() => {
    getQuestions();
  }, []);
  const handleChangeAnswer =
    (questionId: string, name: string) =>
    (e: React.ChangeEvent<HTMLTextAreaElement | HTMLSelectElement>) => {
      const newQuestions = questions.map((question) => {
        if (question.id === questionId) {
          // @ts-ignore
          question[name] = e.target.value;
        }
        return question;
      });
      setQuestions(newQuestions);
    };
  const handleSave = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    const payload: submitSubmissionFromInput = {
      questions: questions.map((question) => ({
        questionId: question.id,
        answer: question.answer || "",
        rating: question.rating || "",
      })),
      isDraft: false,
      submissionId,
    };
    const res = await submitSubmissionFrom(payload);
    if (res.remote === "success") {
      toast("Submitted");
    } else {
      toast("Something went wrong");
    }
  };
  return (
    <div className="mb-[93px]">
      <h2 className="text-center text-[36px]">
        Evaluate yourself on all factors that apply to you since your last
        performance appraisal. If a category does not apply to you, indicate N/A
      </h2>
      <div className="bg-[#C6E7FF] p-[30px] px-[150px] mt-[30px]">
        <h1 className="text-center text-[36px] body-font font-avenirLTStdBlack">
          Rating Scale
        </h1>
        <div className="flex justify-around pt-[30px]">
          {[
            "Outstanding",
            "Meet Expectation",
            "Inexperienced or Improvement Needed or Performance improvement",
          ].map((item, idx) => {
            return (
              <div
                className="flex justify-around items-center w-[298px] h-[92px] rounded-lg bg-[#EAF6FF]"
                key={idx}
              >
                <div className="bg-gradient-to-br from-[#FF1C1C] to-[#9C0000] w-[52px] h-[52px] grid place-items-center body-font font-avenirLTStdBlack text-[36px] text-white rounded-lg">
                  {idx + 1}
                </div>
                <p>{item}</p>
              </div>
            );
          })}
        </div>
      </div>
      <div className="px-[119px] pt-[59px] flex justify-between">
        <div className="flex justify-between bg-[#F7F8FA] w-[100%] h-[59px] items-center px-[30px] rounded-lg border-2 border-[#EFF0F4] text-[24px] font-avenirLTStdBlack">
          Category
        </div>
        {/* <div className="flex justify-between bg-[#F7F8FA] w-[20%]  h-[59px] items-center px-[30px] rounded-lg border-2 border-[#EFF0F4] text-[24px] font-avenirLTStdBlack">
          Self-Rating
        </div>
        <div className="flex justify-between bg-[#F7F8FA] w-[20%]  h-[59px] items-center px-[30px] rounded-lg border-2 border-[#EFF0F4] text-[24px] font-avenirLTStdBlack">
          Comments
        </div> */}
      </div>
      {questions.map((question, idx) => {
        return (
          <div className=" flex flex-col pr-[90px] pl-[90px]">
            <div className="font-avenirLTStdRoman w-[100%]">
              <span className="body-font font-avenirLTStdBlack text-[24px] mb-[29px]">
                {idx + 1}. {question.title}
                <span className="text-red-500">*</span>
              </span>
              <div className="relative w-full lg:max-w-sm">
                <div>Your Rating: </div>
                <select
                  onChange={handleChangeAnswer(question.id, "rating")}
                  value={question.rating}
                  className="w-full p-2.5 text-[16px]  text-gray-500 bg-white border rounded-md shadow-sm outline-none appearance-none font-avenirLTStdBook"
                  disabled={!isDraft}
                >
                  <option>Choose Rating</option>
                  <option value="1">Outstanding</option>
                  <option value="2">Meet Expectation</option>
                  <option value="3">
                    Inexperienced or Improvement Needed or Performance
                    improvement
                  </option>
                </select>
              </div>
              <div className="min-h-[153px] py-1.5">
                <div>Comment: </div>
                <TextareaComponent
                  onChange={handleChangeAnswer(question.id, "answer")}
                  placeholder="Your Comment"
                  value={question.answer}
                  disabled={!isDraft}
                />
              </div>
            </div>
          </div>
        );
      })}
      <div></div>
      <div className="text-center mt-[37px]">
        <FilledButton className="w-[457px] h-[54px]" onClick={handleSave}>
          Submit
        </FilledButton>
      </div>
      <ToastContainer />
    </div>
  );
}

export default StepTwo;
