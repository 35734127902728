import { useCallback, useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import { getUserDetails } from "./api/auth";
import "./App.css";
import Header from "./component/header";
import { useAppDispatch, useAppSelector } from "./redux/hooks";
import { setCurrentUser, setIsLoggedIn } from "./redux/slice/auth";
import { RootState } from "./redux/store";
import { UNAUTHORIZED_ROUTES, AUTHORIZED_ROUTES } from "./utils/constant/route";
import { tokens } from "./utils/localStorage";
import { AuthorizedRoute, UnauthorizedRoute } from "./utils/route";


function App() {
  const dispatch = useAppDispatch();
  console.log(dispatch,'dispatch')
  const { isLoggedIn } = useAppSelector((state: RootState) => state.auth);
  const getCurrentUserDetails = useCallback(async () => {
    const res = await getUserDetails({});
    if (res.remote === "success") {
      dispatch(setCurrentUser(res.data));
    }
  }, [dispatch]);
  useEffect(() => {
    const token = tokens.getAccessToken();
    if (token) {
      dispatch(setIsLoggedIn(true));
    } else {
      dispatch(setIsLoggedIn(false));
    }
  }, [dispatch]);

  useEffect(() => {
    if (isLoggedIn) {
      getCurrentUserDetails();
    }
  }, [isLoggedIn, dispatch, getCurrentUserDetails]);
  return (
    <div className="body-font font-avenirLTStdRoman">
      <Routes>
        {UNAUTHORIZED_ROUTES.map((route) => {
          return (
            <Route
              key={route.id}
              path={route.path}
              element={
                <UnauthorizedRoute>
                  <route.element />
                </UnauthorizedRoute>
              }
            />
          );
        })}

        <Route
          path="/*"
          element={
            <div>
              <div className="pt-[50px] pr-[90px] pl-[90px]">
                <Header />
                <Routes>
                  {AUTHORIZED_ROUTES.map((route) => {
                    return (
                      <Route
                        key={route.id}
                        path={route.path}
                        element={
                          <AuthorizedRoute authRole={route.access}>
                            <route.element />
                          </AuthorizedRoute>
                        }
                      />
                    );
                  })}
                </Routes>
              </div>
            </div>
          }
        />
      </Routes>
    </div>
  );
}

export default App;
