import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { SUBMISSION_STATUS, USER_ROLES } from "../../utils/enum";

export type USER = {
  id: string;
  name: string;
  email: string;
  employeeId: string;
  designation: string;
  designationId?: number;
  location: string;
  role?: USER_ROLES;
  createdAt: string;
  status?: SUBMISSION_STATUS;
};
interface IAuthInitialState {
  isLoggedIn: boolean;
  role?: USER_ROLES;
  currentUser: USER;
  token:string;
}

const initialState: IAuthInitialState = {
  isLoggedIn: false,
  role: undefined,
  token:"",
  currentUser: {
    id: "",
    name: "",
    email: "",
    employeeId: "",
    designation: "",
    location: "",
    createdAt: "",
  },
};

export const authSlice = createSlice({
  name: "auth",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setToken:(state, action: PayloadAction<string>)=>{
      state.token=action.payload;
    },

    setIsLoggedIn: (state, action: PayloadAction<boolean>) => {
      if (!action.payload) {
        state.currentUser = initialState.currentUser;
        state.role = undefined;
      }
      state.isLoggedIn = action.payload;
    },
    setCurrentUser: (state, action: PayloadAction<USER>) => {
      state.currentUser = action.payload;
      state.role = action.payload.role;
    },
  },
});
export const { setIsLoggedIn, setCurrentUser, setToken } = authSlice.actions;
export default authSlice.reducer;
