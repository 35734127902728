import LabeledInputComponent from "./labeledInput";
import SelectInputComponent from "./selectInput";
import TextareaComponent from "./textarea";
import ToggleInputComponent from "./toggle";
export interface IInput
  extends React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {
  label: string;
  password?: boolean;
  errorMessage?: string;
  icon?: JSX.Element;
}
export const LabeledInput = LabeledInputComponent;
export const TextareaInput = TextareaComponent;
export const SelectInput = SelectInputComponent;
export const ToggleInput = ToggleInputComponent;
