import IMAGES from "../../assets/images";
import { FilledButton } from "../button";
import "./style.css";
interface IAppraisal {
  readonly name: string;
  readonly amount: string;
  readonly isPromoted: boolean;
  readonly designation: string;
  readonly rating: string;
  readonly cycle: string;
  readonly email?: string;
  readonly refNumber?: number;
  readonly handleGenerateAppraisalLetter: () => void;
  readonly handleSendAppraisalLetter: () => void;
}
function Appraisal({
  name,
  amount,
  isPromoted,
  designation,
  rating,
  refNumber,
  cycle,
  handleGenerateAppraisalLetter,
  handleSendAppraisalLetter,
}: IAppraisal) {
  return (
    <div>
      <div style={{ fontSize: "17px" }} id="divToPrint">
        <table width="100%">
          <tr>
            <td width="50%">
              <img src={IMAGES.Logo} className="logo" alt="" />
            </td>
            <td
              align="right"
              width="50%"
              style={{
                textAlign: "right",
              }}
            >
              <div>
                Registered Address
                <br />
                Digimonk Technologies Pvt Ltd
                <br />
                Software Technology Park of India (IT Park),
                <br />
                Gwalior (M.P.) 474010 <br />
                Contact No +91 7722-84-5500
                <br />
                <a href="www.digimonk.in">www.digimonk.in</a>
              </div>
            </td>
          </tr>
          <tr>
            <td colSpan={2} align="center">
              <b>STRICTLY CONFIDENTIAL</b>
            </td>
          </tr>
          <tr>
            <td className="past custom_padding">
              <b>
                DATE:{" "}
                {new Date().toLocaleDateString("en-US", {
                  month: "short",
                  day: "numeric",
                  year: "numeric",
                })}{" "}
                <br />
                REF NO: DMT/JUL/DEC/23/{refNumber}
              </b>
            </td>
          </tr>
          <tr style={{ width: "100%" }}>
            <td className="user" colSpan={2}>
              <p>
                <b>Dear: {name.toUpperCase()}</b>
              </p>
              <br />
              <p>
                In recognition of your performance and contribution to
                organization during the review period <b>({cycle})</b>. Your
                Performance Rating has been <b>"{rating.trim()}"</b>
              </p>
              <br />
              {isPromoted && (
                <>
                  <p>
                    {`The Company congratulates you for being promoted as the "${designation.toUpperCase()}"`}
                  </p>
                  <br />
                </>
              )}{" "}
              <p>
                We are pleased to revise your salary to INR{" "}
                <b>{Number(amount).toLocaleString()}</b>/- Per Month w.e.f.{" "}
                <b>Jan 01, 2024</b>. All other terms and conditions of your
                appointment remain unchanged.
              </p>
              <br />
              <p>
                {`We appreciate the efforts put in by you and expect that you would continue to do so in the future.`}
              </p>
              <br />
            </td>
          </tr>
          <tr>
            <td className="custom_padding">
              <p>
                <strong>Yours Cordially,</strong>
              </p>
              <div className="signature">
                <img
                  style={{ width: "165px", height: "55px" }}
                  src={IMAGES.Signature}
                  alt="signature"
                />
              </div>
              <p>
                <strong>Neelam Jain (Director)</strong>
              </p>

              <p>
                <strong>DigiMonk Technologies</strong>
              </p>
            </td>
          </tr>
        </table>
      </div>
      <FilledButton onClick={handleGenerateAppraisalLetter}>
        Download
      </FilledButton>
      &nbsp;
      <FilledButton onClick={handleSendAppraisalLetter}>send</FilledButton>
    </div>
  );
}

export default Appraisal;
