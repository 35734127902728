import urlcat from "urlcat";
import { QUESTION_TYPE } from "../utils/enum";
import api from "./api";
import {
  getEmployerQuestionsResponse,
  transformGetEmployerQuestionsResponse,
} from "./transform/employerQuestions";
import { reviewSubmissionFromInput, submitSubmissionFromInput } from "./types";
export const getEmployerQuestions = async (data: {
  type?: QUESTION_TYPE;
  userId?: string;
}) => {
  const res = await api.request<getEmployerQuestionsResponse[]>({
    url: urlcat("/v1/employer-question", { ...data }),
    method: "GET",
  });
  if (res.remote === "success") {
    return {
      remote: "success",
      data: transformGetEmployerQuestionsResponse(res.data[0]),
    };
  }
  return res;
};

export const submitSubmissionFrom = async (data: submitSubmissionFromInput) => {
  const res = await api.request({
    url: "/v1/employer-question",
    method: "POST",
    data,
  });
  return res;
};

export const reviewSubmissionFrom = async (data: reviewSubmissionFromInput) => {
  const res = await api.request({
    url: "/v1/employer-question/admin-review",
    method: "PUT",
    data,
  });
  return res;
};

export const sendAppraisalPdf = async (data: any) => {
  const res = await api.request({
    url: "/v1/employer-question/send-appraisal",
    method: "POST",
    data,
  });
  return res;
};
