import React, { useCallback, useEffect, useState } from "react";
import { getAdminQuestion } from "../../api/question";
import SVG from "../../assets/svg";
import AdminLayout from "../../component/adminLayout";
import { FilledButton } from "../../component/button";
import { LabeledInput, ToggleInput } from "../../component/input";
import Popup from "../../component/popup";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { setAdminQuestions } from "../../redux/slice/question";
import { RootState } from "../../redux/store";
import EditQuestion from "./editQuestion";

function AdminQuestions() {
  const dispatch = useAppDispatch();
  const [showAddQuestion, setShowAddQuestion] = useState<boolean>(false);
  const questionsList = useAppSelector(
    (state: RootState) => state.adminQuestion.questions
  );
  const [selectedQuestionId, setSelectedQuestionId] = useState("");
  const getQuestions = useCallback(async () => {
    const res = await getAdminQuestion();
    if (res.remote === "success") {
      dispatch(setAdminQuestions(res.data));
    }
  }, [dispatch]);
  const handleEdit = (id: string) => {
    setSelectedQuestionId(id);
  };
  const handleCloseEditUser = () => {
    setShowAddQuestion(false);
    setSelectedQuestionId("");
  };
  useEffect(() => {
    setShowAddQuestion(!!selectedQuestionId);
  }, [selectedQuestionId]);

  useEffect(() => {
    getQuestions();
  }, [getQuestions]);

  return (
    <AdminLayout
      title="Questions List"
      sideContent={
        <>
          <FilledButton
            className="w-[50%] mr-3 h-[75%]"
            onClick={() => setShowAddQuestion(true)}
          >
            Add Questions
          </FilledButton>
          <LabeledInput
            type="text"
            label=""
            placeholder="Search by employee name, code"
            icon={<SVG.Search />}
          />
        </>
      }
    >
      <div className="overflow-x-auto relative">
        <table className="w-full text-sm text-left text-gray-500 ">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50 ">
            <tr>
              <th scope="col" className="py-3 px-6">
                Question
              </th>
              <th scope="col" className="py-3 px-6">
                Status
              </th>
              <th scope="col" className="py-3 px-6">
                Created At
              </th>
              <th scope="col" className="py-3 px-6">
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            {questionsList.map((question) => {
              return (
                <tr className="bg-white border-b ">
                  <td className="py-4 px-6 font-medium text-gray-900 whitespace-nowrap ">
                    {question.title}
                  </td>
                  <td className="py-4 px-6">{question.createdAt}</td>
                  <td className="py-4 px-6">
                    <ToggleInput checked={question.isActive} />
                  </td>
                  <td className="py-4 px-6">
                    <FilledButton onClick={() => handleEdit(question.id)}>
                      Edit
                    </FilledButton>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      {showAddQuestion && (
        <Popup title="Add Employee" handleClose={handleCloseEditUser}>
          <EditQuestion
            questionId={selectedQuestionId}
            handleClose={handleCloseEditUser}
          />
        </Popup>
      )}
    </AdminLayout>
  );
}

export default AdminQuestions;
