import React from "react";
import { Link, useLocation } from "react-router-dom";
import IMAGES from "../../assets/images";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { setIsLoggedIn } from "../../redux/slice/auth";
import { RootState } from "../../redux/store";
import { USER_ROLES } from "../../utils/enum";
import { tokens } from "../../utils/localStorage";

const activeClass =
  "block py-2 pl-3 pr-4 text-white bg-[#B90A0A] rounded md:bg-transparent md:text-[#B90A0A] md:p-0 dark:text-white";
const inActiveClass =
  "block py-2 pl-3 pr-4 text-gray-700 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-[#B90A0A] md:p-0 dark:text-gray-400 md:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent";
function Navbar() {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const { role } = useAppSelector((state: RootState) => state.auth);
  const handleLogout = () => {
    dispatch(setIsLoggedIn(false));
    tokens.removeAccessToken();
  };
  return (
    <nav className=" border-gray-200 px-2 sm:px-4 py-2.5 rounded dark:bg-gray-900 w-full">
      <div className="container flex flex-wrap items-center justify-between mx-auto">
        <a href="https://flowbite.com/" className="flex items-center">
          <img
            src={IMAGES.LogoFull}
            className="h-6 mr-3 sm:h-9"
            alt="digimonk Logo"
          />
        </a>
        <button
          data-collapse-toggle="navbar-default"
          type="button"
          className="inline-flex items-center p-2 ml-3 text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
          aria-controls="navbar-default"
          aria-expanded="false"
        >
          <span className="sr-only">Open main menu</span>
          <svg
            className="w-6 h-6"
            aria-hidden="true"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
              clipRule="evenodd"
            ></path>
          </svg>
        </button>
        <div className="hidden w-full md:block md:w-auto" id="navbar-default">
          <ul className="flex flex-col p-4 mt-4 border border-gray-100 rounded-lg bg-gray-50 md:flex-row md:space-x-8 md:mt-0 md:text-sm md:font-medium md:border-0  dark:bg-gray-800 md:dark:bg-gray-900 dark:border-gray-700">
            {role === USER_ROLES.admin && (
              <>
                <li>
                  <Link
                    to="/employee-list"
                    className={
                      location.pathname === "/employee-list"
                        ? activeClass
                        : inActiveClass
                    }
                    aria-current="page"
                  >
                    Employee List
                  </Link>
                </li>
                <li>
                  <Link
                    to="/admin-questions"
                    className={
                      location.pathname === "/admin-questions"
                        ? activeClass
                        : inActiveClass
                    }
                    aria-current="page"
                  >
                    Questions List
                  </Link>
                </li>
              </>
            )}
            <li onClick={handleLogout}>
              <a href="/" className={inActiveClass} aria-current="page">
                Logout
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
