import React from "react";
import { IButton } from ".";

function FilledButtonComponent({ children, className, ...rest }: IButton) {
  return (
    <button
      className={`bg-[#B90A0A] hover:bg-[#8b0707] text-white py-2 px-4 rounded font-avenirLTStdBlack ${className} `}
      {...rest}
    >
      {children}
    </button>
  );
}

export default FilledButtonComponent;
