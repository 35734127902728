import { AxiosAuthRefreshRequestConfig } from "axios-auth-refresh";
import axios, { AxiosError, AxiosResponse } from "axios";
import { ErrorResult, ServerError, SuccessResult } from "../utils/types";
import { tokens } from "../utils/localStorage";
import { SERVER_URL } from "../utils/serverUrl";
console.log(SERVER_URL,'SERVER_URL')
export const axiosInstance = axios.create({
  baseURL: `${SERVER_URL}/api`,
  headers: {
    "Content-Type": "application/json",
  },
});

axiosInstance.interceptors.response.use(function (response: AxiosResponse) {
  const newAccessToken = response.headers["x-access"];
  if (newAccessToken && tokens.getAccessToken() !== newAccessToken) {
    tokens.setAccessToken(newAccessToken);
  }
  return response;
});

export const request = async <T>(
  config: AxiosAuthRefreshRequestConfig
): Promise<SuccessResult<T> | ErrorResult> => {
  console.log(SERVER_URL,'SERVER_URL')
  try {
    if (!config.headers) {
      config.headers = {};
    }
    if (!config.headers["Content-Type"]) {
      config.headers["Content-Type"] = "application/json";
    }
    if (tokens.getAccessToken()) {
      config.headers["Authorization"] = `Bearer ${tokens.getAccessToken()}`;
    }
    const response = await axiosInstance.request<T>({ ...config });
    return {
      remote: "success",
      data: response.data,
    };
  } catch (error: any) {
    console.log({ error });
    if (error) {
      if (error.response) {
        const axiosError = error as AxiosError<ServerError>;
        if (axiosError.response && axiosError.response.data) {
          let errorMessage = axiosError.response.data.errors;
          // check for 500 to handle message defined by the app
          if (axiosError.response.status === 500) {
            errorMessage = "Internal Server Error";
          } else {
            errorMessage = error.response.data.errors;
          }
          return {
            remote: "failure",
            error: {
              status: axiosError.response.status,
              errors: errorMessage,
            },
          };
        }
      } else {
        const axiosError = error as AxiosError;
        let errorMessage = axiosError.message;

        return {
          remote: "failure",
          error: {
            errors: errorMessage,
          },
        };
      }
    }
    throw error;
  }
};

export const parseResponse = <T>(
  response: any
): SuccessResult<T> | ErrorResult => {
  const data = JSON.parse(response);
  if (data && (data.errors || data.error)) {
    return {
      remote: "failure",
      error: {
        errors: data.errors ?? data.error,
      },
    };
  }
  return {
    remote: "success",
    data,
  };
};

// eslint-disable-next-line import/no-anonymous-default-export
export default { axiosInstance, request, parseResponse };
