import { USER } from "../../redux/slice/auth";
import { SUBMISSION_STATUS, USER_ROLES } from "../../utils/enum";

export type getUserDetailsResponse = {
  _id: string;
  name: string;
  email: string;
  employeeId: string;
  designation: string;
  designationId?:number;
  location: string;
  role: USER_ROLES;
  createdAt: string;
  status?: SUBMISSION_STATUS;
};

export const transformUserDetails = (data: getUserDetailsResponse): USER => {
  return {
    id: data._id,
    name: data.name,
    email: data.email,
    designation: data.designation,
    designationId: data.designationId,
    role: data.role,
    createdAt: data.createdAt,
    employeeId: data.employeeId,
    location: data.location,
    status: data.status,
  };
};
