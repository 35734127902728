import IMAGES from "../../assets/images";
import LoginForm from "./form";

function Login() {
  return (
    <div className="flex min-h-screen">
      <div className="bg-gradient-to-br from-[#FF1C1C] to-[#9C0000] w-1/2 min-h-full grid place-items-center justify-center">
        <div>
          <img src={IMAGES.SideImage} alt="" width={460} height={460} />
        </div>
      </div>
      <div className="w-1/2 min-h-full grid place-items-center bg-white">
        <div className="h-[72%] w-[67%]">
          <div className="mb-[72px]">
            <img alt="" src={IMAGES.LogoFull} />
          </div>
          <div className="">
            <LoginForm />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
