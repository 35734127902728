import urlcat from "urlcat";
import api from "./api";

export type designation = {
    id:number;
    name:string;
}

export type designationListApiResponse = {
    success:number;
    data:designation[];
}

export const designationListApi = async () => {
    const res = await api.request<designationListApiResponse>({
        url:`/v1/user/designation-list`,
        method:"GET"
    });
    if(res.remote === "success"){
        return {
            remote:"success",
            data:res.data.data
        }
    }
    return res;
};
