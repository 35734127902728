import { ReactComponent as EyeOpen } from "./eyeOpen.svg";
import { ReactComponent as EyeOff } from "./eyeOff.svg";
import { ReactComponent as WhiteLogo } from "./whiteLogo.svg";
import { ReactComponent as Logout } from "./logout.svg";
import { ReactComponent as Search } from "./search.svg";
import { ReactComponent as Logo } from "./logo.svg";
const SVG = {
  EyeOpen,
  EyeOff,
  WhiteLogo,
  Logout,
  Search,
  Logo,
};
export default SVG;
