import React, { useRef } from "react";
import { useOnClickOutside } from "usehooks-ts";

interface IPopup {
  title?: string;
  children?: React.ReactNode;
  footer?: React.ReactNode;
  handleClose?: () => void;
}
function Popup({ title, children, footer, handleClose }: IPopup) {
  const ref = useRef(null);
  const handleClickOutside = () => {
    if (handleClose) {
      handleClose();
    }
  };
  useOnClickOutside(ref, handleClickOutside);
  return (
    <>
      <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="relative w-auto my-6 mx-auto max-w-3xl" ref={ref}>
          {/*content*/}
          <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
            {/*header*/}
            <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
              {title && <h3 className="text-3xl font-semibold">{title}</h3>}
            </div>
            {/*body*/}
            <div className="relative p-6 flex-auto">{children}</div>
            {/*footer*/}
            {footer}
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </>
  );
}

export default Popup;
