import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { AdminQuestion } from "../../api/transform/question";

interface IAdminQuestionInitialState {
  questions: AdminQuestion[];
}

const initialState: IAdminQuestionInitialState = {
  questions: [],
};

export const adminQuestionSlice = createSlice({
  name: "adminQuestion",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setAdminQuestions: (state, action: PayloadAction<AdminQuestion[]>) => {
      state.questions = action.payload;
    },
    addQuestionToQuestionList: (
      state,
      action: PayloadAction<AdminQuestion>
    ) => {
      state.questions = [...state.questions, action.payload];
    },
    updateQuestionOfQuestionList: (
      state,
      action: PayloadAction<{ questionId: string; data: AdminQuestion }>
    ) => {
      state.questions = state.questions.map((question) => {
        if (question.id === action.payload.questionId) {
          return action.payload.data;
        }
        return question;
      });
    },
  },
});
export const {
  setAdminQuestions,
  addQuestionToQuestionList,
  updateQuestionOfQuestionList,
} = adminQuestionSlice.actions;
export default adminQuestionSlice.reducer;
