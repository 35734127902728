import React from "react";

interface IError {
  message: string;
}
function ErrorParagraph({ message }: IError) {
  return <p className="text-[#D63301]">{message}</p>;
}

export default ErrorParagraph;
