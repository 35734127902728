import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import {
  getEmployerQuestions,
  submitSubmissionFrom,
} from "../../../api/employerQuestions";
import { AnsweredQuestionType } from "../../../api/transform/employerQuestions";
import { submitSubmissionFromInput } from "../../../api/types";
import { FilledButton, OutlineButton } from "../../../component/button";
import TextareaComponent from "../../../component/input/textarea";
import { QUESTION_TYPE } from "../../../utils/enum";

function StepOne() {
  const navigate = useNavigate();
  const [questions, setQuestions] = useState<AnsweredQuestionType[]>([]);
  const [submissionId, setSubmissionId] = useState<string>("");
  const [isDraft, setIsDraft] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const getQuestions = async () => {
    const res = await getEmployerQuestions({ type: QUESTION_TYPE.input });
    if (res.remote === "success") {
      setSubmissionId(res.data.id);
      setQuestions(res.data.questions);
      setIsDraft(res.data.isDraft);
    }
  };
  useEffect(() => {
    getQuestions();
  }, []);
  const handleChangeAnswer =
    (questionId: string) => (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      const newQuestions = questions.map((question) => {
        if (question.id === questionId) {
          question.answer = e.target.value;
        }
        return question;
      });
      setQuestions(newQuestions);
    };
  const handleSaveDraft =
    (isNext: boolean) => async (e: React.MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();
      if (isNext) {
        console.log({ questions });
        for (let i = 0; i < questions.length; i++) {
          const question = questions[i];
          if (!question.answer) {
            toast("All Field is required");
            return;
          }
        }
      }
      setIsSubmitting(true);

      const payload: submitSubmissionFromInput = {
        questions: questions.map((question) => ({
          questionId: question.id,
          answer: question.answer || "",
          rating: question.rating || "",
        })),
        isDraft: true,
        submissionId,
      };
      const res = await submitSubmissionFrom(payload);
      if (res.remote === "success") {
        if (isNext) {
          navigate("/questions/2");
        } else {
          toast("Drafted");
        }
      } else {
        toast("Something went wrong");
      }
      setIsSubmitting(false);
    };
  return (
    <div className="mb-[93px]">
      {questions.map((question, index) => {
        return (
          <div className="flex flex-col pr-[90px] pl-[90px]" key={index}>
            <span className="body-font font-avenirLTStdBlack text-[24px] mb-[29px]">
              {index + 1}. {question.title}{" "}
              <span className="text-red-500">*</span>
            </span>
            <TextareaComponent
              placeholder="Write On..."
              className="min-h-[153px] py-1.5"
              value={question.answer}
              onChange={handleChangeAnswer(question.id)}
              disabled={!isDraft}
            />
          </div>
        );
      })}

      <div className="mt-[50px] flex justify-around">
        <div>
          {isDraft && (
            <OutlineButton
              className="w-[457px] mr-[39px] h-[54px]"
              onClick={handleSaveDraft(false)}
              disabled={!isDraft}
            >
              Save for later
            </OutlineButton>
          )}
          <FilledButton
            className="w-[457px] h-[54px]"
            onClick={handleSaveDraft(true)}
            disabled={isSubmitting}
          >
            Next
          </FilledButton>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}

export default StepOne;
