import { useState } from "react";
import { CreateUserInput } from "../../../api/types";
import { LabeledInput } from "../../../component/input";
import { USER_ROLES } from "../../../utils/enum";
import { FilledButton } from "../../../component/button";
import { getLoginToken } from "../../../api/auth";
import { setToken } from "../../../redux/slice/auth";
import { useDispatch } from "react-redux";
function HrmsLogin({ setIsClick }: any) {
  const [errors, setErrors] = useState<any>({});
  const dispatch = useDispatch();
  const [loginError, setLoginError] = useState(false);
  const [form, setForm] = useState<CreateUserInput>({
    email: "",
    password: "",
    employeeId: "",
    name: "",
    location: "gwalior",
    designation: "",
    role: USER_ROLES.employee,
  });

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    setForm((prevState) => ({ ...prevState, [e.target.name]: e.target.value }));
  };

  const handleSubmit = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();
    let isValid = true;
    if (form.email === "" && form.password === "") {
      isValid = false;
      setErrors({ message: "Fill All field" });
    }

    if (isValid) {
      const res = await getLoginToken({
        email: `${form.email}`,
        password: `${form.password}`,
      });
      if (res.remote === "success") {
        setIsClick(false);
        dispatch(setToken(res.token));
        console.log(res.token);
      } else {
        setLoginError(true);
      }
    }
  };

  return (
    <div>
      {loginError && (
        <p style={{ color: "red" }}>invalid username or password</p>
      )}
      <form className="w-full">
        <div className="mb-[20px]">
          <LabeledInput
            label="Email"
            placeholder="email"
            name="email"
            value={form.email}
            errorMessage={errors.message}
            onChange={handleChange}
          />
        </div>
        <div className="mb-[20px]">
          <LabeledInput
            label="Password"
            placeholder="password"
            type="password"
            name="password"
            value={form.password}
            errorMessage={errors.message}
            onChange={handleChange}
          />
        </div>

        <div className="mt-[54px] w-full">
          <FilledButton className="w-full" onClick={handleSubmit}>
            Login
          </FilledButton>
        </div>
      </form>
    </div>
  );
}

export default HrmsLogin;
