import FilledButtonComponent from "./filledButton";
import OutlineButtonComponent from "./outlineButton";
export interface IButton
  extends React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  children: React.ReactNode;
}

export const FilledButton = FilledButtonComponent;
export const OutlineButton = OutlineButtonComponent;
